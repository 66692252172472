.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo-container {
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .logo-container img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100% !important;
  }
}

.logo-container img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.menu {
  position: sticky;
  top: 10px;
  font-size: 1.1em;
}

a {
  color: black !Important;
  text-decoration: none;
}

.nav a:hover {
  color: darkgrey !Important;
}

.small-text {
  font-size: 0.6em;
}

.property-column {
  background-color: lightgreen;
  border-radius: 10px;
  width: 100%;
}

.property-column tr td {
  padding: 10px;
}

.site-text a {
  color: black;
}

.site-text a:hover {
  color: darkgrey;
}

.footer a:hover {
  text-decoration: underline;
}

.support-block {
  border-radius: 10px;
  background-color: aqua;
}

.support-block thead td {
  font-weight: 500;
  padding: 0 5px;
}

.support-block td {
  padding: 0 5px;
}